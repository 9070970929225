@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&family=Poppins:wght@200;300;400&display=swap');

* {
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
}

p {
  line-height: 1.8;
}

.App-header {
  /* background-color: #282c34; */
  /* height: 50px; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: left;
  align-content: space-between;
  padding: 15px;
  background-color: #282c34;
  /* font-size: calc(10px + 2vmin); */
  /* color: white; */
  /* border-bottom:1px solid rgb(187, 187, 187); */
}

.App-container {
  background-color: #f6f9ff;
  min-height: 100vh;
  width: 100%;
  font-family: 'Poppins', sans-serif;
}

.logo-container {
  font-size: 20px;
  font-weight: 500;
  font-family: 'Merriweather', serif;
  /* background-color: rgb(254, 255, 185); */
  padding: 10px;
  border-radius: 10px;
}

.logo-home-link {
  text-decoration-line: none;
  color:white;
}

.App-footer {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  justify-content: center;
  padding: 50px;
  color: white;
}

.feature-request-link {
  background-color: rgb(126, 255, 184);
  padding: 10px;
  text-decoration-line: none;
  border-radius: 10px;
  margin-left: auto;
}

.appellate-search-box {
  width: 100%;
  height: 100px;
  border-bottom: 1px solid gray;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.appellate-info-box {
  padding: 50px;
  background-color: rgb(211, 247, 255);
  min-height: 100px;
  display: flex;
  font-size: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.appellate-info-box h2 {
  margin-bottom:20px;
}

.appellate-courts-ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding: 18px;
}

.appellate-courts-li {
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(100, 100, 100);
  /* height: 350px; */
  width: 350px;
  margin: 20px;
  padding: 30px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.appellate-li-title {
  margin-bottom: 20px;
}

.appellate-li-brief-url {
  font-size:20px;
  margin-top:10px;
}

.appellate-li-map {
  width: 300px;
  margin-top: 30px;
  margin-bottom: 30px;
  /* height: 50%; */
  /* background-color: gray; */
}

.appellate-li-title {
  margin-top: 20px;
  margin-bottom: 10px;
}

.appellate-li-districts {
  font-size: 14px;
}

.feature-request-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  font-size:25px;
  padding: 50px;
  padding-top:100px;
}

.appellate-districts-container {
  width: 100%;
}